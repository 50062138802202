import React from 'react';
import styled from 'styled-components';
import Title from './../components/Title';
import { InnerLayout } from './../styles/Layouts';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SchoolIcon from '@material-ui/icons/School';
import ResumeItem from './../components/ResumeItem';
import SkillsItem from './../components/SkillsItem';
function ResumePage() {
    return (
        <ResumePageStyled>
            {/* Resume Section Start Here */}
            <Title title={'Resume'} span={''} />
            <InnerLayout className="resumeSection">
                <div className="education">
                    <h4><SchoolIcon /><span>Education</span></h4>
                    <ResumeItem year={'09/2008 - 12/2012'} title={'Bachelors Degree in Computer Science - CS'} subTitle={'Cornell University College of Engineering'} text={'Computer Science'} />
                </div>
                <div className="experience">
                    <h4> <BusinessCenterIcon /><span>Experience</span></h4>
                    <ResumeItem year={'09/2018 to 11/2022'} title={'Full Stack Web Developer'} subTitle={'NEXTGO.IO'} text={'React, Angular, Next, Gatsby, NodeJS, C#, ASP.NET, Azure, AWS'} />
                    <ResumeItem year={'01/2016 to 08/2018'} title={'Senior Web Developer'} subTitle={'SomyKida, LLC'} text={'React, Angular, NodeJS, Typescript, ASP.NET MVC & Core, AWS, Azure'} />
                    <ResumeItem year={'01/2013 to 12/2015'} title={'Web Developer'} subTitle={'Creative Ingenuity LLC'} text={'Angular, C#, .NET, Microsoft Azure'} />
                </div>
            </InnerLayout>
            {/* Resume Section End Here */}

            {/* Skills Section Start Here */}
            <div className="my-skills">
                <Title title={'My'} span={'Skills'} />
                <InnerLayout>
                    <div className="skills">
                       <SkillsItem title={'React.js'} width={'90%'} text={'90%'} />
                       <SkillsItem title={'Javascript'} width={'90%'} text={'90%'} />
                       <SkillsItem title={'Node.js'} width={'90%'} text={'90%'} />
                       <SkillsItem title={'Angular'} width={'90%'} text={'90%'} />
                       <SkillsItem title={'JavaScript'} width={'90%'} text={'90%'} />
                       <SkillsItem title={'Next.js'} width={'95%'} text={'95%'} />
                       <SkillsItem title={'Gatsby.js'} width={'80%'} text={'80%'} />
                       <SkillsItem title={'Typescript'} width={'95%'} text={'95%'} />
                       <SkillsItem title={'C#'} width={'75%'} text={'75%'} />
                       <SkillsItem title={'ASP.NET'} width={'75%'} text={'75%'} />
                       <SkillsItem title={'ASP.NET Core & MVC'} width={'75%'} text={'75%'} />
                       <SkillsItem title={'MySql'} width={'75%'} text={'75%'} />
                       <SkillsItem title={'MongoDB'} width={'75%'} text={'75%'} />
                       <SkillsItem title={'GraphQL'} width={'75%'} text={'75%'} />
                       <SkillsItem title={'PostgreSQL'} width={'75%'} text={'75%'} />
                       <SkillsItem title={'NoSql'} width={'75%'} text={'75%'} />
                       <SkillsItem title={'AWS'} width={'75%'} text={'75%'} />
                       <SkillsItem title={'Microsoft Azure'} width={'75%'} text={'75%'} />
                       <SkillsItem title={'Github, Gitlab'} width={'75%'} text={'75%'} />
                       <SkillsItem title={'Jira, Asana'} width={'75%'} text={'75%'} />
                    </div>
                </InnerLayout>
            </div>
            {/* Skills Section End Here */}
        </ResumePageStyled>
    )
}

const ResumePageStyled = styled.div`
    .resumeSection{
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap:3rem;
        @media screen and (max-width:992px){
            grid-template-columns: repeat(1, 1fr);
        }
        .education, .experience{
            h4{
                padding: 1rem 0;
                font-size:1.8rem;
                @media screen and (max-width:600px){
                    font-size:1.5rem;
                }
                span{
                    margin-left:.5rem;
                }
            }
            svg{
                color:var(--primary-color);
                font-size:1.8rem;
            }
        }
    }

    .skills{
        display: grid;
        grid-template-columns:repeat(2, 1fr); 
        grid-gap:1.5rem;
        margin-top: 5rem;
        @media screen and (max-width:768px){
            grid-template-columns:repeat(1, 1fr); 
        }
    }
`;

export default ResumePage;
