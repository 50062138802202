import React from 'react';
import styled from 'styled-components';
import Title from './../components/Title';
import { InnerLayout } from './../styles/Layouts';
import ServiceItem from './../components/ServiceItem';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import WebIcon from '@material-ui/icons/Web';
import LanguageIcon from '@material-ui/icons/Language';


function ServicePage() {
    const ecommerce = <ShoppingCartIcon />
    const fullyResponsive = <WebIcon />
    const webDevelopment = <LanguageIcon />

    return (
        <ServicePageStyled>
            {/* Service Start Here */}
            <Title title={'My'} span={'Services'} />
            <InnerLayout>
                <div className="services">
                    <ServiceItem image={webDevelopment} title={'Web Application Development'} paragraph={''} />
                    <ServiceItem image={ecommerce} title={'Ecommerce Development'} paragraph={''} />
                    <ServiceItem image={fullyResponsive} title={'Hybrid Application Development'} paragraph={''} />
                </div>
            </InnerLayout>
            {/* Service End Here */}


        </ServicePageStyled>
    )
}

const ServicePageStyled = styled.div`
    .services{
        display: grid;
        grid-template-columns:repeat(3, 1fr); 
        grid-gap:1.5rem;
        margin-top: 5rem;
        @media screen and (max-width:992px){
            grid-template-columns:repeat(2, 1fr); 
        }
        @media screen and (max-width:600px){
            grid-template-columns:repeat(1, 1fr); 
        }
    }
    .pricing-plans{
        display: grid;
        grid-template-columns:repeat(3, 1fr); 
        grid-gap:1.5rem;
        margin-top: 5rem;
        @media screen and (max-width:992px){
            grid-template-columns:repeat(2, 1fr); 
        }
        @media screen and (max-width:600px){
            grid-template-columns:repeat(1, 1fr); 
        }
    }
`;

export default ServicePage;
