import React from 'react';
import styled from 'styled-components';
import Title from './../components/Title';
import { InnerLayout } from './../styles/Layouts';
import PrimaryButton from './../components/PrimaryButton';
import cvpdf from './../pdf/Robert_Ward_Resume.pdf';

function AboutPage() {
    return (
        <AboutPageStyled>
            {/* about Start Here */}
            <Title title={'About'} span={'Me'} />
            <InnerLayout className="about-section">
                <div className="info-part">
                    <h4>Who am i?</h4>
                    <h3>I am <span>Robert Ward</span></h3>
                    <p>I am a top-performing and passionate full-stack web developer with 10+ years of experience. I am highly skilled in developing, maintaining, and testing web applications with C#, ASP.NET, React, Angular, Node, Next, Tailwind CSS, MongoDB, PostgreSQL, GraphQL, etc. I have expertise in building web pages, integrating functionalities to websites, working with a team of designers and software engineers to implement any ideas or changes, and integrating 3rd party APIs according to project specifications.
</p>
                    <p>I listen to understand the needs of clients and organizations, designing solutions that exceed expectations and succeed within their constraints. I manage budgets, maximize investments, and identify new value streams (revenue, but also engagement, partnerships, and memberships). I am adept at leading in matrix environments among a diverse range of stakeholders (staff, volunteers, partners, members, board leadership).</p>
                    <p>When facing complex issues in a variety of contexts, I create new possibilities out of difficult circumstances – leveraging my strong emotional intelligence and technical and operational expertise.</p>
                    <h4>Skills:</h4>
                    <ul>
                    <li><p>Javascript, React, Angular, NextJS</p></li>
                    <li><p>NodeJS, Typescript, Gatsby.js</p></li>
                    <li><p>C#, ASP.NET, ASP.NET Core & MVC</p></li>
                    <li><p>MySql, MongoDB, PostgreSQL</p></li>
                    <li><p>GraphQL, NoSql</p></li>
                    <li><p>HTML, CSS</p></li>
                    <li><p>Microsoft Azure, AWS</p></li>
                    <li><p>Github, Gitlab</p></li>
                    <li><p>Jira, Asana</p></li>
                    </ul>
                    <br />
                    <a target="_blank" href={cvpdf}><PrimaryButton btn={'Download Resume'} /></a>
                </div>
            </InnerLayout>
            {/* About End Here */}
        </AboutPageStyled>
    )
}


const AboutPageStyled = styled.div`
    .about-section{
        display:grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap:.8rem;
        @media screen and (max-width:800px){
            grid-template-columns: repeat(1, 1fr);
            grid-gap:1.5rem;
        }
        .image-part{
            height:100%;
            width:100%;
            img{
                width:90%;
                height:90%;
                border:2px solid var(--border-color);  
            }
        }
        .info-part{
            h4{
                color:var(--primary-color);
                font-size:1.3rem;
            }
            h3{
                margin:1rem 0;
                font-size:1.9rem;
                span{
                    color:var(--primary-color);
                }
            }
            p{
                font-size:.9rem;
                margin-bottom:.5rem;
            }
            ul{
                li{
                    margin:0px 20px;
                    list-style:outside;
                }
            }
            .information{
                margin:.5rem 0;
                width:90%;
                display:flex;
                justify-content: space-between;
                @media screen and (max-width:500px){
                    width:100%;
                }
                ul{
                    li{
                        margin:.5rem 0;
                        @media screen and (max-width:500px){
                            margin:1rem 0;
                            font-size:.9rem;
                        }
                    }
                }
                .details{
                    color:var(--paragraph-color);
                }
            }
           
        }
    }

    .rec-arrow{
        background-color: var(--border-color);
        &:hover{
            background-color: var(--primary-color);
        }
        &:active,&:focus{
            background-color: var(--primary-color);
        }
    }
    .rec-dot_active{
            box-shadow:none;
            background-color:var(--primary-color);
    }
    @media screen and (max-width:500px){
        .rec.rec-arrow{
            display:none;
        } 
    }
`;

export default AboutPage;
