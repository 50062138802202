import React, { useState, useEffect } from 'react';
import SideBar from './components/Sidebar';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ServicePage from './pages/ServicePage';
import ResumePage from './pages/ResumePage';
import PortfolioPage from './pages/PortfolioPage';
import ContactPage from './pages/ContactPage';
import { Routes, Route, Navigate as Redirect } from 'react-router-dom';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import styled from 'styled-components';

import {MainLayout} from './styles/Layouts';
function App() {
  const [theme, setTheme] = useState('dark-mode');
  const [checked, setChecked] = useState(false);
  const [isNavToggle, setNavToggle] = useState(false)

  useEffect(() => {
    document.documentElement.className = theme;
  }, [theme])

  const themeMode = () => {
    if (theme === 'light-mode') {
      setTheme('dark-mode');
      setChecked(false)
    } else {
      setTheme('light-mode');
      setChecked(true)
    }
  }
  return (
    <div className="App">
      <SideBar isNavToggle={isNavToggle} setNavToggle={setNavToggle} />

      {/* Toggler Button for navbar*/}
      <div className="hamburger-bar">
        <IconButton onClick={() => setNavToggle(!isNavToggle)}>
          <MenuIcon />
        </IconButton>
      </div>

      {/* Main Section Start */}
      <MainSectionStyled>
      <MainLayout>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/about" element={<AboutPage/>}/>
          <Route path="/services" element={<ServicePage />} />
          <Route path="/resume" element={<ResumePage />}/>
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
        </MainLayout>
      </MainSectionStyled>

    </div>
  );
}

const MainSectionStyled = styled.main`
    margin-left: 16.3rem;
    min-height: 100vh;
    @media screen and (max-width:1200px){
      margin-left: 0;
    }
`;

export default App;
