import img1 from './../images/Portfolio/img1.png';
import img2 from './../images/Portfolio/img2.png';
import img3 from './../images/Portfolio/img3.png';
import img4 from './../images/Portfolio/img4.png';
import img5 from './../images/Portfolio/img5.png';
// import img6 from './../images/Portfolio/doblaze.png';
// import img7 from './../images/Portfolio/juilliard.png';
// import img8 from './../images/Portfolio/cooper.png';

const Portfolio = [
    {
        id: 1,
        title: 'Juilliard Recital Management System',
        subTitle: 'React, Redux, ASP.NET MVC 3, PostgreSQL, jQuery, MongoDB, AWS Lambda',
        category: 'ASP.NET',
        image: img1,
        link1: 'https://www.juilliard.edu/',
    },
    {
        id: 2,
        title: 'Blue Sky Path LMS',
        subTitle: 'Angular 9, TypeScript, ASP.NET, Microsoft SQL Server, MongoDB, jQuery, AWS',
        category: 'ASP.NET',
        image: img2,
        link1: 'https://www.blueskyelearn.com/',
    },
    {
        id: 3,
        title: 'Health Marketplace',
        subTitle: 'React, Redux, Node.js, Express.js, MongoDB, jQuery, AWS',
        category: 'React',
        image: img3,
        link1: 'https://www.healthcare.gov',
    },
    {
        id: 4,
        category: 'Angular',
        title: 'Hint Interview',
        subTitle: 'Angular, TypeScript, Node.js, PostgreSQL, Redis, RabbitMQ, AWS',
        image: img4,
        link1: 'https://hello.hinterview.com/',
    },
    {
        id: 5,
        title: 'WorthFm',
        subTitle: 'React, Redux, ASP.NET MVC 3, NGinx, Unicorn, Oracle, MongoDB, AWS',
        category: 'ASP.NET',
        image: img5,
        link1: 'https://worthfm.com/',
    },
    // {
    //     id: 6,
    //     title: 'Doblaze-Social Network',
    //     subTitle: 'Laravel, MySQL, HTML, CSS3, jQuery, Bootstrap',
    //     category: 'Laravel',
    //     // image: img6,
    //     link1: 'https://doblaze.com/login',
    // },
    // {
    //     id: 7,
    //     title: 'Juilliard',
    //     subTitle: 'HTML5, React, Redux, jQuery, Python/Django, Celery, PostgreSQL, SAML/Okt',
    //     category: 'Python/Django',
    //     // image: img7,
    //     link1: 'https://www.juilliard.edu/',
    // },
    // {
    //     id: 7,
    //     title: 'Cooper',
    //     subTitle: 'Angular 6, ASP.NET, Web Forms, Web API, Entity Framework, MS SQL Server, Hangfire library, AWS',
    //     category: 'ASP.NET',
    //     // image: img8,
    //     link1: 'https://www.copper.com/',
    // }
];

export default Portfolio;
